import React, { Component } from 'react';
import * as JsSearch from 'js-search';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import Container from '../Layout/Container';
import { ListSimple } from '../Post/PostList';

const StyledSearch = styled.section`
  margin: 1.5rem 0;
`;

const StyledForm = styled.form`
  position: relative;

  input {
    appearance: none;
    background: var(--color-bg-white);
    display: inline-block;
    border: 1px solid var(--color-mute);
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(85,95,110,.2);
    padding: 10px 10px 10px 45px;
    vertical-align: middle;
    white-space: normal;
    height: 100%;
    width: 100%;
  }

  button {
    position: absolute;
    top: 50%;
    left: 8px;
    margin-top: -10px;
    border: 0;
    height: 20px;
    vertical-align: top;
    width: 20px;
    display: inline-block;
    font-size: 20px;
    background: var(--color-bg-white);
    color: var(--color-mute);
  }
`;

export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recipes: props.recipes || [],
      search: [],
      searchResults: [],
      searchQuery: '',
      maxSearchResults: process.env.GATSBY_MAX_SEARCH_RESULTS,
    };
  }

  async componentDidMount() {
    this.rebuildIndex();
  }

  handleSubmit = (event) => {
    event.preventDefault();
  }

  rebuildIndex = () => {
    const { recipes } = this.state;
    const search = new JsSearch.Search('slug');

    search.indexStrategy = new JsSearch.PrefixIndexStrategy();
    search.sanitizer = new JsSearch.LowerCaseSanitizer();
    search.searchIndex = new JsSearch.TfIdfSearchIndex('slug');

    search.addIndex('title');
    search.addIndex('description');

    search.addDocuments(recipes);

    this.setState({ search });
  }

  searchData = (event) => {
    console.log('asdfasd');
    const { search, maxSearchResults } = this.state;
    const currentSearch = event.target.value;

    if (currentSearch.length < 3) {
      this.setState({
        searchQuery: event.target.value,
        searchResults: [],
      });
      return;
    }

    let queryResult = search.search(currentSearch);

    if (queryResult.length > maxSearchResults) {
      queryResult = queryResult.slice(0, maxSearchResults);
    }

    this.setState({
      searchQuery: event.target.value,
      searchResults: queryResult,
    });
  }

  render() {
    const {
      recipes, searchResults, searchQuery, maxSearchResults,
    } = this.state;

    let result = searchResults;

    if (result.length === 0) {
      result = recipes.slice(0, maxSearchResults);
    }

    return (
      <StyledSearch>
        <Container>
          <StyledForm onSubmit={this.handleSubmit}>
            <input
              id="Search"
              value={searchQuery}
              onChange={this.searchData}
              placeholder="Qué quieres cocinar"
            />
            <button type="submit">
              <FaSearch />
            </button>
          </StyledForm>
          <h2>{searchQuery === '' || searchQuery.length < 3
            ? 'Mostrando últimos resultados'
            : `Mostrando resultados para tu búsqueda "${searchQuery}"`}
          </h2>
          <ListSimple recipes={result} />
        </Container>
      </StyledSearch>
    );
  }
}
