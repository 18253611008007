import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Search from '../components/Search/Search';

export default function SearchPage({ data }) {
  const recipes = data.recipes.nodes || [];

  return (
    <div>
      <SEO title="Buscador de recetas">
        <meta name="robots" content="NOINDEX, NOFOLLOW" />
      </SEO>
      <Search recipes={recipes} />
    </div>
  );
}

export const query = graphql`
  query {
    recipes: allRecipesJson(sort: { fields: created_at, order: DESC }) {
      nodes {
        slug
        description
        title
        time
        people
        price
        created_at
        categories
      }
    }
  }
`;
